<template>
  <div class="mx-auto max-w-screen-xl py-4 px-4 space-y-5">
    <PageHeader :title="event.name">
      <template #breadcrumb>
        <PageHeaderItem :title="$t('nav.event')" href="/events"></PageHeaderItem>
        <PageHeaderItem :title="event.name" :href="`/events/${event.uid}/analytics`"></PageHeaderItem>
        <PageHeaderItem :title="$t('events.nav.profile')"></PageHeaderItem>
      </template>

      <template #functional>
        <template v-if="event.status === 'complete'">
          <a :href="`/events/${event.uid}.xlsx`" class="inline-block btn btn-primary" target="_blank">
            <i class="fa-solid fa-file-arrow-down fa-fw me-2"></i>
            <span v-t="'helpers.export'"></span>
          </a>
          <Link v-if="pundit_policy.update" :href="`/events/${event.uid}/edit`" class="inline-block btn btn-primary">
            <i class="fa-solid fa-pen fa-fw me-2"></i>
            <span v-t="'helpers.edit'"></span>
          </Link>
        </template>
        <template v-else-if="event.status === 'fail'">
          <button v-if="pundit_policy.destroy" @click="onDeleted(event)" class="inline-block btn btn-danger">
            <i class="fa-solid fa-trash me-2"></i>
            <span v-t="'helpers.delete'"></span>
          </button>
        </template>
      </template>
    </PageHeader>

    <section class="space-y-5">
      <!-- nav -->
      <Nav :event="event"></Nav>

      <div class="w-full mx-auto space-y-5 md:w-2/3">
        <!-- profile -->
        <div class="card">
          <div class="card-header">
            <div class="flex items-center">
              <div v-t="'events.show.profile'" class="flex"></div>

              <div class="flex-auto text-end">
              </div>
            </div>
          </div>

          <div class="card-body space-y-2">
            <dl>
              <dt v-t="'activerecord.attributes.event.name'" class="mb-2 text-sm leading-none text-gray-500 dark:text-white"></dt>
              <dd class="font-semibold text-gray-900 dark:text-gray-400">{{ event.name }}</dd>
            </dl>
            <dl>
              <dt v-t="'activerecord.attributes.event.event_date'" class="mb-2 text-sm leading-none text-gray-500 dark:text-white"></dt>
              <dd class="font-semibold text-gray-900 dark:text-gray-400">{{ dateFormat(event.event_date) }}</dd>
            </dl>
            <dl>
              <dt v-t="'activerecord.attributes.event.status'" class="mb-2 text-sm leading-none text-gray-500 dark:text-white"></dt>
              <dd class="font-semibold text-gray-900 dark:text-gray-400">
                <template v-if="event.status === 'complete'">
                  <span class="badge badge-primary">{{ $t(`activerecord.attributes.event.status_list.${event.status}`) }}</span>
                </template>
                <template v-else-if="event.status === 'fail'">
                  <span class="badge badge-danger">{{ $t(`activerecord.attributes.event.status_list.${event.status}`) }}</span>
                </template>
                <template v-else>
                  <span class="badge">{{ $t(`activerecord.attributes.event.status_list.${event.status}`) }}</span>
                </template>
              </dd>
            </dl>
            <dl>
              <dt v-t="'activerecord.attributes.event.created_at'" class="mb-2 text-sm leading-none text-gray-500 dark:text-white"></dt>
              <dd class="font-semibold text-gray-900 dark:text-gray-400">{{ dateTimeFormat(event.created_at) }}</dd>
            </dl>
            <dl>
              <dt v-t="'activerecord.attributes.event.updated_at'" class="mb-2 text-sm leading-none text-gray-500 dark:text-white"></dt>
              <dd class="font-semibold text-gray-900 dark:text-gray-400">{{ dateTimeFormat(event.updated_at) }}</dd>
            </dl>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { router } from '@inertiajs/vue3'
import I18n from '@commons/i18n'
import Nav from './_nav.vue'

defineProps({
  event: { type: Object, required: true },
  pundit_policy: { type: Object, required: true }
})

const onDeleted = (event) => {
  if (confirm(I18n.t('helpers.are_you_sure'))) {
    const url = `/events/${event.uid}`
    router.delete(url)
  }
}
</script>
