<template>
  <form @submit.prevent="onSubmit" action="#" class="space-y-5">
    <div class="card">
      <div v-t="'companies.form.profile'" class="card-header"></div>

      <div class="card-body">
        <!-- name -->
        <div class="grid grid-cols-2 gap-4">
          <!-- last name -->
          <div>
            <label for="last_name" class="label">
              {{ $t('activerecord.attributes.member.last_name') }}
              <abbr title="required">*</abbr>
            </label>
            <input v-model="form.member.last_name" type="text" last_name="last_name" id="last_name" class="input" required="" />

            <div v-if="form?.errors?.['last_name']" class="label-error">
              {{ form?.errors?.['last_name'][0] }}
            </div>
          </div>

          <!-- first name -->
          <div>
            <label for="first_name" class="label">
              {{ $t('activerecord.attributes.member.first_name') }}
              <abbr title="required">*</abbr>
            </label>
            <input v-model="form.member.first_name" type="text" name="first_name" id="first_name" class="input" required="" />

            <div v-if="form?.errors?.['first_name']" class="label-error">
              {{ form?.errors?.['first_name'][0] }}
            </div>
          </div>
        </div>

        <!-- email -->
        <div>
          <label for="email" class="label">
            {{ $t('activerecord.attributes.member.email') }}
            <abbr title="required">*</abbr>
          </label>
          <input v-model="form.member.email" type="email" name="email" id="email" class="input" required="" />

          <div v-if="form?.errors?.['email']" class="label-error">
            {{ form?.errors?.['email'][0] }}
          </div>
        </div>

        <!-- phone number -->
        <div class="grid grid-cols-2 gap-4">
          <!-- telephone -->
          <div>
            <label for="telephone" class="label">
              {{ $t('activerecord.attributes.member.telephone') }}
            </label>
            <input v-model="form.member.telephone" type="text" name="telephone" id="telephone" class="input" />

            <div v-if="form?.errors?.['telephone']" class="label-error">
              {{ form?.errors?.['telephone'][0] }}
            </div>
          </div>

          <!-- mobile -->
          <div>
            <label for="mobile_number" class="label">
              {{ $t('activerecord.attributes.member.mobile_number') }}
            </label>
            <input v-model="form.member.mobile_number" type="text" name="mobile_number" id="mobile_number" class="input" />

            <div v-if="form?.errors?.['mobile_number']" class="label-error">
              {{ form?.errors?.['mobile_number'][0] }}
            </div>
          </div>
        </div>

        <!-- company -->
        <div>
          <label for="company_id" class="label">
            {{ $t('activerecord.attributes.member.company_name') }}
            <abbr title="required">*</abbr>
          </label>
          <multiselect v-model="companySelected"
                       id="company_id"
                       :options="options.company_options"
                       :multiple="false"
                       :close-on-select="true"
                       :clear-on-select="false"
                       :preserve-search="true"
                       :placeholder="$t('helpers.plz_select')"
                       :searchable="true"
                       label="name"
                       track-by="id"
                       :allow-empty="false">
          </multiselect>

          <div v-if="form?.errors?.['company']" class="label-error">
            {{ form?.errors?.['company'][0] }}
          </div>
        </div>

        <!-- job_title -->
        <div>
          <label for="job_title" class="label">
            {{ $t('activerecord.attributes.member.job_title') }}
            <abbr title="required">*</abbr>
          </label>
          <input v-model="form.member.job_title" type="text" name="job_title" id="job_title" class="input" required="" />

          <div v-if="form?.errors?.['job_title']" class="label-error">
            {{ form?.errors?.['job_title'][0] }}
          </div>
        </div>

        <!-- job_level -->
        <div>
          <label for="job_level" class="label">
            {{ $t('activerecord.attributes.member.job_level') }}
            <abbr title="required">*</abbr>
          </label>
          <select v-model="form.member.job_level" id="job_level" class="select" required="">
            <option v-t="'helpers.plz_select'" disabled></option>
            <option v-for="(lang, index) in options.job_level_options" :key="index" :value="lang" v-t="`activerecord.attributes.member.job_level_list.${lang}`"></option>
          </select>

          <div v-if="form?.errors?.['job_level']" class="label-error">
            {{ form?.errors?.['job_level'][0] }}
          </div>
        </div>

        <!-- subscribed -->
        <div>
          <label for="subscribed" class="label">
            {{ $t('activerecord.attributes.member.subscribed') }}
            <abbr title="required">*</abbr>
          </label>
          <select v-model="form.member.subscribed" id="subscribed" class="select" required="">
            <option v-t="'activerecord.attributes.member.subscribed_list.true'" :value="true"></option>
            <option v-t="'activerecord.attributes.member.subscribed_list.false'" :value="false"></option>
          </select>

          <div v-if="form?.errors?.['subscribed']" class="label-error">
            {{ form?.errors?.['subscribed'][0] }}
          </div>
        </div>

        <!-- note -->
        <div class="pb-2">
          <label for="note" class="label">
            {{ $t('activerecord.attributes.member.note') }}
          </label>
          <input v-model="form.member.note" type="text" name="note" id="note" class="input" />

          <div v-if="form?.errors?.['note']" class="label-error">
            {{ form?.errors?.['note'][0] }}
          </div>
        </div>
      </div>
    </div>

    <div class="space-x-2">
      <template v-if="newRecord">
        <button v-t="'helpers.create'" :disabled="form.processing" type="submit" class="btn btn-primary"></button>
        <Link v-t="'helpers.cancel'" :href="`/members`" class="btn"></Link>
      </template>
      <template v-else>
        <button v-t="'helpers.update'" :disabled="form.processing" type="submit" class="btn btn-primary"></button>
        <Link v-t="'helpers.cancel'" :href="`/members/${member.uid}`" class="btn"></Link>
      </template>
    </div>
  </form>
</template>

<script setup>
import { ref, watch } from 'vue'
import { useForm } from '@inertiajs/vue3'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'

const props = defineProps({
  member: { type: Object, required: true },
  options: { type: Object, required: true }
})

const defaultForm = {
  member: {
    last_name: props.member.last_name,
    first_name: props.member.first_name,
    email: props.member.email,
    telephone: props.member.telephone,
    mobile_number: props.member.mobile_number,
    job_title: props.member.job_title,
    job_level: props.member.job_level,
    subscribed: props.member.subscribed,
    note: props.member.note,
    company_id: props.member.company_id
  }
}

const form = useForm(defaultForm)
const newRecord = props.member.new_record
const companySelected = ref(props.member.company_id ? props.options.company_options.find((company) => company.id === props.member.company_id) : null)

const updateCompanyId = (company) => {
  form.member.company_id = company ? company.id : null
}

watch(companySelected, (newValue) => {
  updateCompanyId(newValue)
})

const onSubmit = () => {
  if (newRecord) {
    form.post('/members')
  } else {
    form.put(`/members/${props.member.uid}`)
  }
}
</script>
