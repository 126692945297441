<template>
  <form @submit.prevent="onSubmit" action="#" class="space-y-5">
    <div class="card">
      <div v-t="'events.form.profile'" class="card-header"></div>

      <div class="card-body">
        <!-- name -->
        <div>
          <label for="name" class="label">
            {{ $t('activerecord.attributes.event.name') }}
            <abbr title="required">*</abbr>
          </label>
          <input v-model="form.event.name" type="text" name="name" id="name" class="input" required="">

          <div v-if="form?.errors?.['name']" class="label-error">
            {{ form?.errors?.['name'][0] }}
          </div>
        </div>

        <!-- event_date -->
        <div>
          <label for="event_date" class="label">
            {{ $t('activerecord.attributes.event.event_date') }}
            <abbr title="required">*</abbr>
          </label>

          <VueDatePicker v-model="form.event.event_date" required auto-apply
                         :enable-time-picker="false" format="yyyy-MM-dd"></VueDatePicker>

          <div v-if="form?.errors?.['event_date']" class="label-error">
            {{ form?.errors?.['event_date'][0] }}
          </div>
        </div>

        <!-- origin -->
        <div v-if="newRecord">
          <label for="origin" class="label">
            {{ $t('activerecord.attributes.event.origin') }}
          </label>
          <input @input="onInput" type="file" name="origin" id="origin" class="input">
          <p class="mt-1 text-sm text-gray-500 dark:text-gray-300">
            {{ $t('events.form.origin_desc') }}

            <a v-t="'events.form.sample_file'" class="font-medium text-blue-600 underline hover:no-underline dark:text-blue-500" href="/sample_files/leads_sample.xlsx" target="_blank"></a>
          </p>


          <div v-if="form?.errors?.['origin']" class="label-error">
            {{ form?.errors?.['origin'][0] }}
          </div>
        </div>
      </div>
    </div>

    <div class="space-x-2">
      <template v-if="newRecord">
        <button v-t="'helpers.create'" :disabled="form.processing" type="submit" class="btn btn-primary"></button>
        <Link v-t="'helpers.cancel'" :href="`/events`" class="btn"></Link>
      </template>
      <template v-else>
        <button v-t="'helpers.update'" :disabled="form.processing" type="submit" class="btn btn-primary"></button>
        <Link v-t="'helpers.cancel'" :href="`/events/${event.uid}`" class="btn"></Link>
      </template>
    </div>
  </form>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

const props = defineProps({
  event: { type: Object, required: true }
})

const defaultForm = {
  event: {
    name: props.event.name,
    event_date: props.event.event_date
  }
}
const form = useForm(defaultForm)

const newRecord = props.event.new_record

const onInput = (e) => {
  form.event.origin = e.target.files[0]
}

const onSubmit = () => {
  if (newRecord) {
    form.post('/events')
  } else {
    form.put(`/events/${props.event.uid}`)
  }
}
</script>
