<template>
  <div class="mx-auto max-w-screen-xl py-4 px-4 space-y-5">
    <PageHeader :title="$t('nav.settings.industry')">
      <template #breadcrumb>
        <PageHeaderItem :title="$t('nav.setting')"></PageHeaderItem>
        <PageHeaderItem :title="$t('nav.settings.industry')"></PageHeaderItem>
      </template>

      <template #functional>
        <Link href="/settings/industries/new" class="inline-block btn btn-primary">
          <i class="fa-solid fa-plus me-1"></i>
          <span v-t="'helpers.add'"></span>
        </Link>
      </template>
    </PageHeader>

    <section>
      <DataTable :resources="data" :paginate="paginate" :searchPlaceholder="$t('settings_industries.index.search_placeholder')">
        <template #thead>
          <DataTableHead id="name" :text="$t('activerecord.attributes.industry.name')" class="text-center" />
          <DataTableHead id="created_at" :text="$t('activerecord.attributes.industry.created_at')" class="text-center" defaultOrderColumn />
          <DataTableHead />
        </template>

        <template #tbody-tr="{resource: industry}">
          <td class="px-4 py-3 text-center font-semibold whitespace-nowrap text-blue-600 hover:underline dark:text-blue-400">
            <Link :href="`/settings/industries/${industry.uid}/edit`">{{ industry.name }}</Link>
          </td>
          <td class="px-4 py-3 text-center whitespace-nowrap">{{ dateTimeFormat(industry.created_at) }}</td>
          <td>
            <button id="action-dropdown-button" :data-dropdown-toggle="`action-dropdown-${industry.uid}`"
                    class="inline-flex items-center p-0.5 text-sm font-medium text-center text-gray-500 hover:text-gray-800 rounded-lg focus:outline-none dark:text-gray-400 dark:hover:text-gray-100" type="button">
              <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
              </svg>
            </button>
            <Teleport to="body">
              <div :id="`action-dropdown-${industry.uid}`" class="hidden z-10 w-44 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600">
                <ul class="py-1 text-sm text-center text-gray-700 dark:text-gray-200" aria-labelledby="action-dropdown-button">
                  <li>
                    <Link v-t="'helpers.edit'" :href="`/settings/industries/${industry.uid}/edit`"
                          class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"></Link>
                  </li>
                </ul>
                <div class="py-1 text-sm text-center text-gray-700 dark:text-gray-200">
                  <ConfirmModal class="dropdown-menu-item"
                              @submit="onDeleted(industry)"
                              :buttonText="$t('helpers.delete')"
                              :modalTitle="$t('helpers.are_you_sure_delete')"
                              modalSubmitClass="btn-danger"
                              :modalSubmitText="$t('helpers.delete')">

                    <Detail :industry="industry"></Detail>
                  </ConfirmModal>
                </div>
              </div>
            </Teleport>
          </td>
        </template>
      </DataTable>
    </section>
  </div>
</template>

<script setup>
import { router } from '@inertiajs/vue3'
import Detail from './_detail.vue'

defineProps({
  data: { type: Array, required: true },
  paginate: { type: Object, required: true }
})

const onDeleted = (industry) => {
  const url = `/settings/industries/${industry.uid}`
  router.delete(url)
}
</script>
