<template>
  <header>
    <nav class="bg-white border-b px-4 lg:px-6 py-2.5 dark:bg-gray-700 dark:border-gray-600">
      <div class="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
        <Link href="/" class="flex items-center">
          <img src="https://flowbite.com/docs/images/logo.svg" class="mr-3 h-6 sm:h-9" alt="Logo" />

          <span v-t="'title'" class="self-center text-xl font-semibold whitespace-nowrap dark:text-white"></span>
        </Link>
        <div class="flex items-center lg:order-2 space-x-1">
          <DarkToggle></DarkToggle>

          <button id="dropdownUserAvatarButton" data-dropdown-toggle="dropdownLanguage" class="text-gray-500 bg-white focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-3 py-2 dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:border-gray-600 dark:focus:bg-gray-600 dark:focus:ring-gray-500" type="button">
            <span class="sr-only">Open user menu</span>
            <i class="fa-solid fa-globe text-base"></i>
          </button>

          <!-- Dropdown menu -->
          <div id="dropdownLanguage" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
            <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownUserAvatarButton">
              <li>
                <a v-t="'langs.en'" :href="`${currentUrl}?locale=en`" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"></a>
              </li>
              <li>
                <a v-t="'langs.zh_tw'" :href="`${currentUrl}?locale=zh-TW`" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"></a>
              </li>
            </ul>
          </div>

          <button ref="dropdownUserBtnEl" id="dropdownUserButton" class="text-gray-500 bg-white focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-3 py-2 dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:border-gray-600 dark:focus:bg-gray-600 dark:focus:ring-gray-500" type="button">
            <span class="sr-only">Open user menu</span>
            <i class="fas fa-user-circle text-base"></i>
          </button>

          <!-- Dropdown menu -->
          <div ref="dropdownUserMenuEl" id="dropdownAvatar" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
            <div class="px-4 py-3 text-sm text-gray-900 dark:text-white">
              <div>{{ currentUser.name }}</div>
              <div class="font-medium truncate">{{ currentUser.email }}</div>
            </div>
            <!-- <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownUserButton">
              <li>
                <Link @click="onToggleDropdownUserMenu" v-t="'space.user_sidebar.edit_password'" href="/accounts/passwords/edit" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"></Link>
              </li>
            </ul> -->
            <div class="py-2">
              <Link v-t="'sign_out'" href="/users/sign_out" method="delete" as="button" class="w-full text-start px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"></Link>
            </div>
          </div>

          <button data-collapse-toggle="mobile-menu" type="button" class="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="mobile-menu-2" aria-expanded="false">
            <span class="sr-only">Open main menu</span>
            <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
            <svg class="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
          </button>
        </div>
        <div class="hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1" id="mobile-menu">
          <ul class="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
            <!-- <li>
              <Link v-t="'nav.dashboard'" href="/" class="block py-2 pr-4 pl-3 text-gray-700 rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 dark:text-white" aria-current="page"></Link>
            </li> -->
            <li>
              <Link v-t="'nav.event'" href="/events" class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"></Link>
            </li>
            <li>
              <Link v-t="'nav.company'" href="/companies" class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"></Link>
            </li>
            <li>
              <Link v-t="'nav.member'" href="/members" class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"></Link>
            </li>
            <li v-if="inertiaHeaderPunditPolicy.settings">
              <button ref="dropdownSettingsBtnEl" id="dropdownSettingsLink" class="flex items-center justify-between w-full py-2 px-3 text-gray-700 rounded md:hover:bg-transparent md:border-0 md:p-0 md:w-auto dark:text-gray-400 dark:hover:text-white dark:focus:text-white dark:border-gray-700 dark:hover:bg-white md:dark:hover:bg-transparent">
                {{ $t('nav.setting') }}
                <svg class="w-2.5 h-2.5 ms-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
                </svg>
              </button>
              <div ref="dropdownSettingsMenuEl" id="dropdownSettings" class="z-10 hidden font-normal bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
                <ul class="py-2 text-sm text-gray-700 dark:text-gray-400" aria-labelledby="dropdownLargeButton">
                  <li>
                    <Link @click="onToggleDropdownSettingMenu" v-t="'nav.settings.industry'" href="/settings/industries" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"></Link>
                  </li>
                  <li>
                    <Link @click="onToggleDropdownSettingMenu" v-t="'nav.settings.user'" href="/settings/users" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"></Link>
                  </li>
                  <li>
                    <Link @click="onToggleDropdownSettingMenu" v-t="'nav.settings.setup'" href="/settings/ai_setup/edit" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"></Link>
                  </li>
                  <!-- <li>
                    <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Earnings</a>
                  </li> -->
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { usePage } from '@inertiajs/vue3'
import { Dropdown } from 'flowbite'
import DarkToggle from '@components/dark-toggle.vue'

const currentUser = computed(() => usePage().props.currentUser)
const inertiaHeaderPunditPolicy = computed(() => usePage().props.inertiaHeaderPunditPolicy)
const currentUrl = computed(() => usePage().url.split('?')[0] )
const dropdownUserBtnEl = ref(null)
const dropdownUserMenuEl = ref(null)
const dropdownSettingsBtnEl = ref(null)
const dropdownSettingsMenuEl = ref(null)
const dropdownSetting = ref(null)

const onToggleDropdownSettingMenu = () => {
  dropdownSetting.value.toggle()
}

onMounted(() => {
  if (inertiaHeaderPunditPolicy.value.settings) {
    dropdownSetting.value = new Dropdown(dropdownSettingsMenuEl.value, dropdownSettingsBtnEl.value)
  }
  new Dropdown(dropdownUserMenuEl.value, dropdownUserBtnEl.value)
})
</script>
