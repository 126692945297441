// style start --
// tailwindcss
import '@stylesheets/core.tailwind.scss'
// -- end style

// bootstrap
// import * as bootstrap from 'bootstrap'

// i18n
import VueI18n from '@commons/vue-i18n'

// Global components
import AppGlobalComponents from '@commons/app-global-components'

import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'
import { createPinia } from 'pinia'

import ApplicationLayout from '@layouts/application.vue'

// remove title element
const titleEl = document.querySelector('head title')
if (titleEl) titleEl.remove()

createInertiaApp({
  resolve: (name) => {
    const pages = import.meta.glob(['../views/**/*.vue'], { eager: true })
    const page = pages[`../views/${name}.vue`]

    // if (!name.startsWith('managers/')) page.default.layout = page.default.layout || AdminLayout
    page.default.layout = page.default.layout || ApplicationLayout

    return page
  },
  setup({ el, App, props, plugin }) {
    createApp({ render: () => h(App, props) })
      .use(plugin)
      .use(VueI18n)
      .use(createPinia())
      .use(AppGlobalComponents)
      .mount(el)
  },
  progress: {
    color: '#6366f1'
  }
})
