<template>
  <!-- Modal toggle -->
  <a @click.prevent="toggleModal" href="javascript:;" class="btn btn-primary">
    {{ $t('event_emails.index.replace') }}

    <template v-if="eventEmailsChecked.length > 0">
      ({{ eventEmailsChecked.length }})
    </template>
  </a>

  <!-- Main modal -->
  <Teleport to="body">
    <!-- Main modal -->
    <div ref="modalEl" tabindex="-1" aria-hidden="true" class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
      <div class="relative p-4 w-full max-w-3xl max-h-full">
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <!-- Modal header -->
          <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
            <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
              <template v-if="eventEmailsChecked.length > 0">
                  {{ $t('event_emails.index.email_desc.replace') }}
                </template>
                <template v-else>
                  {{ $t('event_emails.index.email_desc.replace_all') }}
                </template>

              <template v-if="eventEmailsChecked.length > 0">
                ({{ eventEmailsChecked.length }})
              </template>
            </h3>
            <button @click="closeModal" type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
              <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
              </svg>
              <span class="sr-only">Close modal</span>
            </button>
          </div>

          <!-- Modal body -->
          <form @submit.prevent="onReplaceEmails" class="p-4 md:p-5">
            <div class="grid gap-4 mb-4 grid-cols-2">
              <!-- mail type -->
              <div class="col-span-2">
                <label for="email_type" class="label">
                  {{ $t('event_emails.index.email_type') }}
                </label>
                <select v-model="emailType" name="email_type" id="email_type" class="select">
                  <option value="default_email">{{ $t('event_emails.index.email_types.default_email') }}</option>
                  <option value="custom_email">{{ $t('event_emails.index.email_types.custom_email') }}</option>
                </select>
              </div>

              <!-- subject -->
              <div class="col-span-2">
                <label v-t="'activerecord.attributes.event_email_content.subject'" for="subject" class="label"></label>
                <input v-model="form.replace_emails.subject" :disabled="emailType === 'default_email'" type="text" name="subject" id="subject" class="input" required="">
              </div>

              <!-- content -->
              <div class="col-span-2">
                <div class="flex justify-between items-center space-x-2 mb-1">
                  <label v-t="'activerecord.attributes.event_email_content.content'" for="content" class="label mb-0"></label>
                </div>
                <textarea v-model="form.replace_emails.content" :disabled="emailType === 'default_email'" rows="17" name="content" id="content" class="input" required="" />
              </div>
            </div>
          </form>

          <!-- Modal footer -->
          <div class="flex items-center justify-end gap-2 p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
            <button @click="onReplaceEmails" :disabled="form.processing" type="button" class="btn btn-primary">
              {{ $t('helpers.replace_emails') }}
              <template v-if="form.processing">
                <i class="fa-solid fa-rotate fa-spin"></i>
              </template>
            </button>
            <button v-t="'helpers.cancel'" @click="closeModal" type="button" class="btn"></button>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useForm } from '@inertiajs/vue3'
import { Modal } from 'flowbite'

const props = defineProps({
  event: { type: Object, required: true },
  eventEmailsChecked: { type: Array, required: true },
  defaultEmail: { type: Object, required: true }
})

const modalEl = ref(undefined)
const modal = ref(undefined)
const emailType = ref('default_email')

const defaultForm = {
  replace_emails: {
    subject: props.defaultEmail.subject,
    content: props.defaultEmail.content
  }
}
const form = useForm(defaultForm)

const onReplaceEmails = () => {
  const url = `/events/${props.event.uid}/emails/replace_emails`
  const uids = props.eventEmailsChecked.map((eventEmail) => eventEmail.uid)
  form.replace_emails.uids = uids

  form.post(url, {
    onSuccess: () => {
      window.location.reload()
    }
  })
}

const toggleModal = () => {
  modal.value.toggle()
}

const closeModal = () => {
  modal.value.hide()
}

onMounted(() => {
  modal.value = new Modal(modalEl.value)
})
</script>
