<template>
  <div class="mx-auto max-w-screen-xl py-4 px-4 space-y-5">
    <PageHeader :title="$t('events.nav.event_verifier_emails')">
      <template #breadcrumb>
        <PageHeaderItem :title="$t('nav.event')" href="/events"></PageHeaderItem>
        <PageHeaderItem :title="event.name" :href="`/events/${event.uid}/analytics`"></PageHeaderItem>
        <PageHeaderItem :title="$t('events.nav.event_verifier_emails')"></PageHeaderItem>
      </template>

      <template v-if="event.waiting_send_count > 0" #functional>
        <ConfirmModal class="inline-block1 btn btn-primary"
                      @submit="onSendAllEmails"
                      :buttonText="$t('helpers.send_all_emails', { count: event.waiting_send_count })"
                      :modalTitle="$t('helpers.send')"
                      modalSubmitClass="inline-block btn-primary"
                      :modalSubmitText="$t('helpers.send')">

          <SendAllMailsDetail :event="event"></SendAllMailsDetail>
        </ConfirmModal>
      </template>
    </PageHeader>

    <section class="space-y-5">
      <!-- nav -->
      <Nav :event="event"></Nav>

      <div class="grid grid-cols-none md:grid-cols-12 gap-4">
        <div class="col-span-full md:col-span-2">
          <EmailNav :event="event"></EmailNav>
        </div>
        <div class="col-span-full md:col-span-10">
          <DataTable :resources="data" :paginate="paginate" :searchPlaceholder="$t('event_verifier_emails.index.search_placeholder')">
            <template #thead>
              <DataTableHead :text="$t('activerecord.attributes.member.full_name')" class="text-center" />
              <DataTableHead :text="$t('activerecord.attributes.member.email')" class="text-center" />
              <DataTableHead :text="$t('activerecord.attributes.event_email.status')" class="text-center whitespace-nowrap" />
              <DataTableHead :text="$t('activerecord.attributes.company.industry')" class="text-center"
                             :selectOptions="select_options.industry_options" id="industry" />
              <DataTableHead :text="$t('activerecord.attributes.event_email_content.content')" class="text-center whitespace-nowrap" />
              <DataTableHead id="created_at" :text="$t('activerecord.attributes.company.created_at')" class="text-center" defaultOrderColumn />
              <DataTableHead></DataTableHead>
            </template>

            <template #tbody-tr="{resource: eventEmail}">
              <td class="px-4 py-3 text-center font-semibold whitespace-nowrap text-blue-600 dark:text-blue-400">
                {{ eventEmail.member.full_name }}
              </td>
              <td class="px-4 py-3 text-center whitespace-nowrap">
                {{ eventEmail.member.email }}
              </td>
              <td class="px-4 py-3 text-center whitespace-nowrap">
                <template v-if="eventEmail.status === 'sent'">
                  <span class="badge badge-primary">{{ $t(`activerecord.attributes.event_email.status_list.${eventEmail.status}`) }}</span>
                </template>
                <template v-else-if="eventEmail.status === 'failed'">
                  <span class="badge badge-danger">{{ $t(`activerecord.attributes.event_email.status_list.${eventEmail.status}`) }}</span>
                </template>
                <template v-else-if="eventEmail.status === 'queued'">
                  <span class="badge badge-info">{{ $t(`activerecord.attributes.event_email.status_list.${eventEmail.status}`) }}</span>
                </template>
                <template v-else>
                  <span class="badge">{{ $t(`activerecord.attributes.event_email.status_list.${eventEmail.status}`) }}</span>
                </template>
              </td>
              <td class="px-4 py-3 text-center whitespace-nowrap">
                <span class="badge badge-primary">{{ eventEmail.industry.name }}</span>
              </td>
              <td class="px-4 py-3 text-center">
                <EmailContent :event="event" :eventEmail="eventEmail"></EmailContent>
              </td>
              <td class="px-4 py-3 text-center whitespace-nowrap">{{ dateTimeFormat(eventEmail.created_at) }}</td>
              <td>
                <!-- dropdown -->
                <button :ref="(el) => (dropdownButtonEl[eventEmail.uid] = el)" id="action-dropdown-button"
                        class="inline-flex items-center p-0.5 text-sm font-medium text-center text-gray-500 hover:text-gray-800 rounded-lg focus:outline-none dark:text-gray-400 dark:hover:text-gray-100" type="button">
                  <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
                  </svg>
                </button>
                <Teleport to="body">
                  <div :ref="(el) => (dropdownMenuEl[eventEmail.uid] = el)" :id="`action-dropdown-${eventEmail.uid}`" class="hidden z-10 w-44 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600">
                    <div class="py-1 text-sm text-center text-gray-700 dark:text-gray-200">
                      <ConfirmModal class="dropdown-menu-item"
                                    @submit="onSend(eventEmail)"
                                    :buttonText="$t('helpers.send')"
                                    :modalTitle="$t('helpers.are_you_sure_send')"
                                    modalSubmitClass="btn-primary"
                                    :modalSubmitText="$t('helpers.send')">

                        <Detail :eventEmail="eventEmail"></Detail>
                      </ConfirmModal>
                    </div>
                    <ul class="py-1 text-sm text-center text-gray-700 dark:text-gray-200" aria-labelledby="action-dropdown-button">
                      <li>
                        <Link @click.prevent="onToggleDropdownMenu(eventEmail.uid)" v-t="'event_verifier_emails.index.cancel_confirm'"
                              :href="`/events/${event.uid}/verifier_emails/${eventEmail.uid}`"
                              method="delete" as="button"
                              class="w-full py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"></Link>
                      </li>
                    </ul>
                  </div>
                </Teleport>
              </td>
            </template>
          </DataTable>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import Nav from './../_nav.vue'
import EmailNav from './../_email_nav.vue'
import EmailContent from './_email_content.vue'
import Detail from './_detail.vue'
import SendAllMailsDetail from './_send_all_mails_detail.vue'
import { ref, onMounted } from 'vue'
import { router } from '@inertiajs/vue3'
import { Dropdown } from 'flowbite'
import axios from 'axios'

const props = defineProps({
  event: { type: Object, required: true },
  data: { type: Array, required: true },
  paginate: { type: Object, required: true },
  select_options: { type: Object, required: true }
})

const dropdownButtonEl = ref({})
const dropdownMenuEl = ref({})
const dropdown = {}

const onToggleDropdownMenu = (eventEmailUid) => {
  dropdown[eventEmailUid].toggle()
}

const onSend = (eventEmail) => {
  const url = `/events/${props.event.uid}/verifier_emails/${eventEmail.uid}/send_mail`
  axios.post(url)
    .then((response) => {
      router.reload()
    })
    .catch((error) => {
      console.log(error)
    })
}

const onSendAllEmails = () => {
  const url = `/events/${props.event.uid}/verifier_emails/send_all_mails`
  router.post(url)
}

onMounted(() => {
  for (const eventEmail of props.data) {
    dropdown[eventEmail.uid] = new Dropdown(dropdownMenuEl.value[eventEmail.uid], dropdownButtonEl.value[eventEmail.uid])
  }
})
</script>
