<template>
  <button @click="onClick" id="theme-toggle" type="button" class="text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-600 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5">
    <!-- moon icon -->
    <svg ref="themeToggleDarkIcon" id="theme-toggle-dark-icon" class="hidden w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
      <path fill-rule="evenodd" d="M11.7 2a10 10 0 1 0 9.8 13.3 1 1 0 0 0-1-1.3H20a8 8 0 0 1-7.6-10.6l.1-.4a1 1 0 0 0-.8-1Z" clip-rule="evenodd"/>
    </svg>

    <!-- sun icon -->
    <svg ref="themeToggleLightIcon" id="theme-toggle-light-icon" class="hidden w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
      <path fill-rule="evenodd" d="M13 3a1 1 0 1 0-2 0v2a1 1 0 1 0 2 0V3ZM6.3 5A1 1 0 0 0 5 6.2l1.4 1.5a1 1 0 0 0 1.5-1.5L6.3 5Zm12.8 1.3A1 1 0 0 0 17.7 5l-1.5 1.4a1 1 0 0 0 1.5 1.5L19 6.3ZM12 7a5 5 0 1 0 0 10 5 5 0 0 0 0-10Zm-9 4a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2H3Zm16 0a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2h-2ZM7.8 17.7a1 1 0 1 0-1.5-1.5L5 17.7A1 1 0 1 0 6.3 19l1.5-1.4Zm9.9-1.5a1 1 0 0 0-1.5 1.5l1.5 1.4a1 1 0 0 0 1.4-1.4l-1.4-1.5ZM13 19a1 1 0 1 0-2 0v2a1 1 0 1 0 2 0v-2Z" clip-rule="evenodd"/>
    </svg>
  </button>
</template>

<script setup>
// ref from https://flowbite.com/docs/customize/dark-mode/
import { ref, onMounted } from 'vue'
import { useThemeStore } from '@stores/theme/store'

const themeToggleDarkIcon = ref(null)
const themeToggleLightIcon = ref(null)
const htmlEl = document.documentElement
const store = useThemeStore()

const onClick = () => {
  // toggle icons inside button
  themeToggleDarkIcon.value.classList.toggle('hidden')
  themeToggleLightIcon.value.classList.toggle('hidden')

  // if set via local storage previously
  if (localStorage.getItem('color-theme')) {
    if (localStorage.getItem('color-theme') === 'light') {
      htmlEl.classList.add('dark')
      localStorage.setItem('color-theme', 'dark')
    } else {
      htmlEl.classList.remove('dark')
      localStorage.setItem('color-theme', 'light')
    }

  // if NOT set via local storage previously
  } else {
    if (htmlEl.classList.contains('dark')) {
      htmlEl.classList.remove('dark')
      localStorage.setItem('color-theme', 'light')
    } else {
      htmlEl.classList.add('dark')
      localStorage.setItem('color-theme', 'dark')
    }
  }

  store.themeMode = localStorage.getItem('color-theme')
}

onMounted(() => {
  if (localStorage.getItem('color-theme') === 'dark' || (!('color-theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
    htmlEl.classList.add('dark')
  } else {
    htmlEl.classList.remove('dark')
  }

  // Change the icons inside the button based on previous settings
  if (localStorage.getItem('color-theme') === 'dark' || (!('color-theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
    themeToggleLightIcon.value.classList.remove('hidden')
    store.themeMode = 'dark'
  } else {
    themeToggleDarkIcon.value.classList.remove('hidden')
    store.themeMode = 'light'
  }
})
</script>
