<template>
  <form @submit.prevent="onSubmit" action="#" class="space-y-5">
    <div class="card">
      <div v-t="'companies.form.profile'" class="card-header"></div>

      <div class="card-body">
        <!-- name -->
        <div>
          <label for="name" class="label">
            {{ $t('activerecord.attributes.company.name') }}
            <abbr title="required">*</abbr>
          </label>
          <input v-model="form.company.name" type="text" name="name" id="name" class="input" required="">

          <div v-if="form?.errors?.['name']" class="label-error">
            {{ form?.errors?.['name'][0] }}
          </div>
        </div>

        <!-- vat_number -->
        <div>
          <label for="vat_number" class="label">
            {{ $t('activerecord.attributes.company.vat_number') }}
          </label>
          <input v-model="form.company.vat_number" type="text" name="vat_number" id="vat_number" class="input">

          <div v-if="form?.errors?.['vat_number']" class="label-error">
            {{ form?.errors?.['vat_number'][0] }}
          </div>
        </div>

        <!-- industry -->
        <div>
          <label for="industry" class="label">
            {{ $t('activerecord.attributes.company.industry') }}
            <abbr title="required">*</abbr>
          </label>
          <select v-model="form.company.industry_id" id="industry" class="select" required="">
            <option v-t="'helpers.plz_select'" :value="null" disabled></option>
            <option v-for="(industry, index) in options.industry_options" :key="index" :value="industry.value">{{ industry.label }}</option>
          </select>

          <div v-if="form?.errors?.['industry']" class="label-error">
            {{ form?.errors?.['industry'][0] }}
          </div>
        </div>

        <!-- website -->
        <div>
          <label for="website" class="label">
            {{ $t('activerecord.attributes.company.website') }}
          </label>
          <input v-model="form.company.website" type="url" name="website" id="website" class="input">

          <div v-if="form?.errors?.['website']" class="label-error">
            {{ form?.errors?.['website'][0] }}
          </div>
        </div>

        <!-- ipo -->
        <div>
          <label for="ipo" class="label">
            {{ $t('activerecord.attributes.company.ipo') }}
          </label>
          <select v-model="form.company.ipo" id="ipo" class="select" required="">
            <option v-t="'activerecord.attributes.company.ipo_list.true'" :value="true"></option>
            <option v-t="'activerecord.attributes.company.ipo_list.false'" :value="false"></option>
          </select>

          <div v-if="form?.errors?.['ipo']" class="label-error">
            {{ form?.errors?.['ipo'][0] }}
          </div>
        </div>

        <!-- capital_amount -->
        <div>
          <label for="capital_amount" class="label">
            {{ $t('activerecord.attributes.company.capital_amount') }}
          </label>
          <input v-model="form.company.capital_amount" type="text" name="capital_amount" id="capital_amount" class="input">

          <div v-if="form?.errors?.['capital_amount']" class="label-error">
            {{ form?.errors?.['capital_amount'][0] }}
          </div>
        </div>

        <!-- note -->
        <div class="pb-2">
          <label for="note" class="label">
            {{ $t('activerecord.attributes.company.note') }}
          </label>
          <input v-model="form.company.note" type="text" name="note" id="note" class="input">

          <div v-if="form?.errors?.['note']" class="label-error">
            {{ form?.errors?.['note'][0] }}
          </div>
        </div>
      </div>
    </div>

    <div class="space-x-2">
      <template v-if="newRecord">
        <button v-t="'helpers.create'" :disabled="form.processing" type="submit" class="btn btn-primary"></button>
        <Link v-t="'helpers.cancel'" :href="`/companies`" class="btn"></Link>
      </template>
      <template v-else>
        <button v-t="'helpers.update'" :disabled="form.processing" type="submit" class="btn btn-primary"></button>
        <Link v-t="'helpers.cancel'" :href="`/companies/${company.uid}`" class="btn"></Link>
      </template>
    </div>
  </form>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3'

const props = defineProps({
  company: { type: Object, required: true },
  options: { type: Object, required: true }
})

const defaultForm = {
  company: {
    name: props.company.name,
    vat_number: props.company.vat_number,
    industry_id: props.company.industry_id,
    website: props.company.website,
    ipo: props.company.ipo,
    capital_amount: props.company.capital_amount,
    note: props.company.note
  }
}
const form = useForm(defaultForm)

const newRecord = props.company.new_record

const onSubmit = () => {
  if (newRecord) {
    form.post('/companies')
  } else {
    form.put(`/companies/${props.company.uid}`)
  }
}
</script>
