<template>
  <canvas ref="canvasEl"></canvas>
</template>

<script setup>
import { ref, toRefs, onMounted } from 'vue'
import { Chart, DoughnutController, ArcElement, Tooltip, Title, Legend } from 'chart.js'
Chart.register(DoughnutController, ArcElement, Tooltip, Title, Legend)

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  options: {
    type: Object,
    required: true
  }
})

const { data, options } = toRefs(props)
const canvasEl = ref(undefined)

onMounted(() => {
  new Chart(canvasEl.value, {
    type: 'doughnut',
    data: data.value,
    options: options.value
  })
})
</script>
