<template>
  <!-- Modal toggle -->
  <template v-if="eventEmail.user.name">
    <a @click="toggleModal" class="btn btn-primary py-2 px-2.5 text-xs">
      {{ eventEmail.user.name }}
    </a>
  </template>
  <template v-else>
    <a @click="toggleModal" class="btn btn-danger py-2 px-2.5 text-xs">
      {{ $t('activerecord.attributes.event_email.no_assign') }}
    </a>
  </template>

  <!-- Main modal -->
  <Teleport to="body">
    <!-- Main modal -->
    <div ref="modalEl" tabindex="-1" aria-hidden="true" class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
      <div class="relative p-4 w-full max-w-3xl max-h-full">
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <!-- Modal header -->
          <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
            <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
              {{ $t('activerecord.attributes.event_email.switch_sales_rep.title') }}
            </h3>
            <button @click="closeModal" type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
              <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
              </svg>
              <span class="sr-only">Close modal</span>
            </button>
          </div>

          <!-- Modal body -->
          <form @submit.prevent="onUpdate" class="p-4 md:p-5">
            <div class="grid gap-4 mb-4 grid-cols-2">
              <!-- mail type -->
              <div class="col-span-2">
                <label for="switch_sales_rep" class="label">
                  {{ $t('activerecord.attributes.event_email.switch_sales_rep.name') }}
                </label>
                <select v-model="form.user_id" name="switch_sales_rep" id="switch_sales_rep" class="select">
                  <option v-for="sales_rep in salesOptions" :key="sales_rep.id" :value="sales_rep.id">
                    {{ sales_rep.name }}
                  </option>
                </select>
              </div>
            </div>
          </form>

          <!-- Modal footer -->
          <div class="flex items-center justify-end gap-2 p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
            <button @click="onUpdate" :disabled="form.processing" type="button" class="btn btn-primary">
              {{ $t('helpers.update') }}
              <template v-if="form.processing">
                <i class="fa-solid fa-rotate fa-spin"></i>
              </template>
            </button>
            <button v-t="'helpers.cancel'" @click="closeModal" type="button" class="btn"></button>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useForm } from '@inertiajs/vue3'
import { Modal } from 'flowbite'

const props = defineProps({
  event: { type: Object, required: true },
  eventEmail: { type: Object, required: true },
  salesOptions: { type: Array, required: true }
})

const modalEl = ref(undefined)
const modal = ref(undefined)

const defaultForm = {
  user_id: props.salesOptions.find(option => option.name === props.eventEmail.user.name)?.id || null
}
const form = useForm(defaultForm)

const onUpdate = () => {
  form.put(`/events/${props.event.uid}/emails/${props.eventEmail.uid}/switch_sales_rep`, {
    onSuccess: () => {
      closeModal()
    },
    preserveScroll: true
  })
}

const toggleModal = () => {
  modal.value.toggle()
}

const closeModal = () => {
  modal.value.hide()
}

onMounted(() => {
  modal.value = new Modal(modalEl.value)
})
</script>
