<template>
  <form @submit.prevent="onSubmit" action="#" class="space-y-5">
    <div class="card">
      <div v-t="'nav.settings.email_setup'" class="card-header"></div>

      <div class="card-body">
        <!-- lang -->
        <div>
          <label for="lang" class="label">
            {{ $t('settings_email_setup.edit.lang') }}
          </label>
          <select v-model="form.portal_setup.settings.lang" name="lang" id="lang" class="select">
            <option value="zh-TW">{{ $t('settings_email_setup.edit.langs.zh-TW') }}</option>
            <option value="en">{{ $t('settings_email_setup.edit.langs.en') }}</option>
            <option value="ja">{{ $t('settings_email_setup.edit.langs.ja') }}</option>
          </select>
        </div>

        <!-- subject -->
        <div>
          <label for="subject" class="label inline-flex items-center">
            {{ $t('settings_email_setup.edit.subject') }}

            <button class="ms-1" data-popover-target="popover-email_subject" data-popover-placement="bottom-start" type="button">
              <svg class="w-4 h-4 text-gray-400 hover:text-gray-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path></svg><span class="sr-only">Show information</span>
            </button>
          </label>

          <input v-model="form.portal_setup.settings.subject" type="text" name="subject" id="subject" class="input">
        </div>

        <!-- content -->
        <div>
          <label for="content" class="label inline-flex items-center">
            {{ $t('settings_email_setup.edit.content') }}

            <button class="ms-1" data-popover-target="popover-email_content" data-popover-placement="bottom-start" type="button">
              <svg class="w-4 h-4 text-gray-400 hover:text-gray-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path></svg><span class="sr-only">Show information</span>
            </button>
          </label>

          <textarea v-model="form.portal_setup.settings.content" name="content" id="content" class="input" rows="17"></textarea>
        </div>
      </div>

      <div class="card-footer space-x-3">
        <button v-t="'helpers.update'" :disabled="!form.isDirty || form.processing" type="submit" class="btn btn-primary btn-sm"></button>
      </div>
    </div>
  </form>

  <div data-popover id="popover-email_subject" role="tooltip" class="absolute z-10 invisible inline-block text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 w-72 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-400">
    <div class="p-3 space-y-2">
      <p class="mt-1 text-xs text-gray-500">
        {{ $t('settings_email_setup.edit.subject_desc.desc1') }}
      </p>
      <p class="mt-1 text-xs text-gray-500">
        {{ $t('settings_email_setup.edit.subject_desc.desc2') }}
      </p>
      <p class="mt-1 text-xs text-gray-500">
        {{ $t('settings_email_setup.edit.subject_desc.desc3') }}
      </p>
    </div>
    <div data-popper-arrow></div>
  </div>

  <div data-popover id="popover-email_content" role="tooltip" class="absolute z-10 invisible inline-block text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 w-72 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-400">
    <div class="p-3 space-y-2">
      <p class="mt-1 text-xs text-gray-500">
        {{ $t('settings_email_setup.edit.content_desc.desc1') }}
      </p>
      <p class="mt-1 text-xs text-gray-500">
        {{ $t('settings_email_setup.edit.content_desc.desc2') }}
      </p>
      <p class="mt-1 text-xs text-gray-500">
        {{ $t('settings_email_setup.edit.content_desc.desc3') }}
      </p>
      <p class="mt-1 text-xs text-gray-500">
        {{ $t('settings_email_setup.edit.content_desc.desc4') }}
      </p>
      <p class="mt-1 text-xs text-gray-500">
        {{ $t('settings_email_setup.edit.content_desc.desc5') }}
      </p>
      <p class="mt-1 text-xs text-gray-500">
        {{ $t('settings_email_setup.edit.content_desc.desc6') }}
      </p>
    </div>
    <div data-popper-arrow></div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { useForm } from '@inertiajs/vue3'
import { initPopovers } from 'flowbite'

const props = defineProps({
  emailSetup: { type: Object, required: true },
})

const defaultForm = {
  portal_setup: {
    settings: {
      lang: props.emailSetup.lang,
      subject: props.emailSetup.subject,
      content: props.emailSetup.content
    }
  }
}
const form = useForm(defaultForm)

const onSubmit = () => {
  form.put('/settings/email_setup')
}

onMounted(() => {
  initPopovers()
})
</script>
