<template>
  <div class="space-y-4">
    <!-- basic -->
    <div class="list-group">
      <div class="list-group-item header">
        {{ $t('nav.settings.setup') }}
      </div>
      <Link :class="isActive('/settings/ai_setup/edit')" href="/settings/ai_setup/edit" class="list-group-item">
        {{ $t('settings.nav.ai_setup') }}
      </Link>
      <Link :class="isActive('/settings/google_setup/edit')" href="/settings/google_setup/edit" class="list-group-item">
        {{ $t('settings.nav.google_setup') }}
      </Link>
      <Link :class="isActive('/settings/email_setup/edit')" href="/settings/email_setup/edit" class="list-group-item">
        {{ $t('settings.nav.email_setup') }}
      </Link>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { usePage } from '@inertiajs/vue3'

const pageUrl = computed(() => usePage().url )

const isActive = (url) => {
  let active = false
  if (Array.isArray(url)) {
    active = url.includes(pageUrl.value)
  } else {
    active = pageUrl.value === url
  }

  return { active }
}
</script>
