<script setup>
import { computed } from 'vue'
import { usePage } from '@inertiajs/vue3'

const props = defineProps({
  message: String, full: Boolean
})
const flash = computed(() => usePage().props.flash)

const message = computed(() => {
  if (props.message) return props.message
  if (flash.value?.notice) return flash.value.notice

  return undefined
})
</script>

<template>
  <template v-if="full">
    <div v-if="message" class="alert alert-primary alert-full" role="alert">
      <div class="mx-auto max-w-screen-xl">
        <span>{{ message }}</span>
      </div>
    </div>
  </template>
  <template v-else>
    <div v-if="message" class="alert alert-primary" role="alert">
      <span>{{ message }}</span>
    </div>
  </template>
</template>
