<template>
  <form @submit.prevent="onSubmit" action="#" class="space-y-5">
    <div class="card">
      <div v-t="'settings_google_setup.edit.custom_search'" class="card-header"></div>

      <div class="card-body">
        <!-- search_api_key -->
        <div>
          <label for="search_api_key" class="label">
            {{ $t('settings_google_setup.edit.search_api_key') }}
          </label>
          <div class="input-group">
            <input v-if="apiGoogleCustomSearch.search_api_key && !enabledUpdatingSearchApiKey" :value="hideValue(apiGoogleCustomSearch.search_api_key)" disabled type="text" name="search_api_key" id="search_api_key" class="input">
            <input v-else v-model="form.portal_setup.settings.search_api_key" type="text" name="search_api_key" id="search_api_key" class="input">
            <button v-if="apiGoogleCustomSearch.search_api_key && !enabledUpdatingSearchApiKey" @click.prevent="onUpdateSearchApiKey" v-t="'helpers.edit'" type="button" class="btn btn-primary btn-sm"></button>
          </div>

          <p class="mt-1 text-xs text-gray-500">
            <a href="https://developers.google.com/custom-search/v1/introduction#identify_your_application_to_google_with_api_key" target="_blank" class="underline">{{ $t('settings_google_setup.edit.search_api_key_desc') }}</a>
          </p>
        </div>

        <!-- search_engine_id -->
        <div>
          <label for="search_engine_id" class="label">
            {{ $t('settings_google_setup.edit.search_engine_id') }}
          </label>
          <div class="input-group">
            <input v-if="apiGoogleCustomSearch.search_engine_id && !enabledUpdatingSearchEngineId" :value="hideValue(apiGoogleCustomSearch.search_engine_id)" disabled type="text" name="search_engine_id" id="search_engine_id" class="input">
            <input v-else v-model="form.portal_setup.settings.search_engine_id" type="text" name="search_engine_id" id="search_engine_id" class="input">
            <button v-if="apiGoogleCustomSearch.search_engine_id && !enabledUpdatingSearchEngineId" @click.prevent="onUpdateSearchEngineId" v-t="'helpers.edit'" type="button" class="btn btn-primary btn-sm"></button>
          </div>

          <p class="mt-1 text-xs text-gray-500">
            <a href="https://developers.google.com/custom-search/docs/tutorial/creatingcse" target="_blank" class="underline">{{ $t('settings_google_setup.edit.search_engine_id_desc') }}</a>
          </p>
        </div>
      </div>

      <div class="card-footer space-x-3">
        <button v-t="'helpers.update'" :disabled="!form.isDirty || form.processing" type="submit" class="btn btn-primary btn-sm"></button>
      </div>
    </div>
  </form>
</template>

<script setup>
import { ref } from 'vue'
import { useForm } from '@inertiajs/vue3'

const props = defineProps({
  apiGoogleCustomSearch: { type: Object, required: true },
})

const enabledUpdatingSearchApiKey = ref(false)
const enabledUpdatingSearchEngineId = ref(false)
const defaultForm = {
  portal_setup: {
    settings: {
      search_api_key: props.apiGoogleCustomSearch.search_api_key,
      search_engine_id: props.apiGoogleCustomSearch.search_engine_id
    }
  }
}
const form = useForm(defaultForm)

const onUpdateSearchApiKey = () => {
  form.portal_setup.settings.search_api_key = null
  enabledUpdatingSearchApiKey.value = true
}

const onUpdateSearchEngineId = () => {
  form.portal_setup.settings.search_engine_id = null
  enabledUpdatingSearchEngineId.value = true
}

const hideValue = (str) => {
  return `${str.slice(0, 4)}●●●●●●●${str.slice(-4)}`
}

const onSubmit = () => {
  form.put('/settings/api_google_custom_search', {
    onSuccess: (page) => {
      enabledUpdatingSearchApiKey.value = false
      enabledUpdatingSearchEngineId.value = false
    }
  })
}
</script>
