<template>
  <div class="tabs">
    <ul>
      <!-- profile -->
      <li :class="navIsActive(`/companies/${company.uid}`)">
        <Link v-t="'companies.nav.profile'" :href="`/companies/${company.uid}`"></Link>
      </li>

      <!-- member -->
      <li :class="navIsActive(`/companies/${company.uid}/members`)">
        <Link :href="`/companies/${company.uid}/members`">
          {{ $t('companies.nav.member') }}
          ({{ company.members_count }})
        </Link>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { onUpdated } from 'vue'
import { initDropdowns } from 'flowbite'

defineProps({
  company: { type: Object, required: true }
})

// initialize components based on data attribute selectors
onUpdated(() => {
  initDropdowns()
})
</script>
