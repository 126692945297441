<template>
  <div class="mx-auto max-w-screen-xl py-4 px-4 space-y-5">
    <PageHeader :title="$t('nav.member')">
      <template #breadcrumb>
        <PageHeaderItem :title="$t('nav.member')"></PageHeaderItem>
      </template>

      <template #functional>
        <Link href="/members/new" class="inline-block btn btn-primary">
          <i class="fa-solid fa-plus me-1"></i>
          <span v-t="'helpers.add'"></span>
        </Link>
      </template>
    </PageHeader>

    <section>
      <DataTable :resources="data" :paginate="paginate" :searchPlaceholder="$t('members.index.search_placeholder')">
        <template #thead>
          <DataTableHead id="name" :text="$t('activerecord.attributes.member.full_name')" class="text-center whitespace-nowrap" disableOrder />
          <DataTableHead id="email" :text="$t('activerecord.attributes.member.email')" class="text-center whitespace-nowrap" />
          <DataTableHead id="telephone" :text="$t('activerecord.attributes.member.telephone')" class="text-center whitespace-nowrap" />
          <DataTableHead id="mobile_number" :text="$t('activerecord.attributes.member.mobile_number')" class="text-center whitespace-nowrap" />
          <DataTableHead id="company_name" :text="$t('activerecord.attributes.member.company_name')" class="text-center whitespace-nowrap" disableOrder/>
          <DataTableHead id="job_level" :text="$t('activerecord.attributes.member.job_level')" class="text-center whitespace-nowrap"
                         :selectOptions="select_options.job_level" />
          <DataTableHead id="job_title" :text="$t('activerecord.attributes.member.job_title')" class="text-center whitespace-nowrap" />
          <DataTableHead id="subscribed" :text="$t('activerecord.attributes.member.subscribed')" class="text-center whitespace-nowrap" />
          <DataTableHead id="created_at" :text="$t('activerecord.attributes.member.created_at')" class="text-center whitespace-nowrap" defaultOrderColumn />
          <DataTableHead></DataTableHead>
        </template>

        <template #tbody-tr="{resource: member}">
          <td class="px-4 py-3 text-center font-semibold whitespace-nowrap text-blue-600 hover:underline dark:text-blue-400">
            <Link :href="`/members/${member.uid}`">{{ member.full_name }}</Link>
          </td>
          <td class="px-4 py-3 text-center underline whitespace-nowrap">
            <span class="">{{ member.email }}</span>
          </td>
          <td class="px-4 py-3 text-center whitespace-nowrap">
            <span>{{ member.telephone }}</span>
          </td>
          <td class="px-4 py-3 text-center whitespace-nowrap">
            <span>{{ member.mobile_number }}</span>
          </td>
          <td class="px-4 py-3 text-center font-semibold whitespace-nowrap text-blue-600 hover:underline dark:text-blue-400">
            <Link :href="`/companies/${member.company_uid}`">{{ member.company_name }}</Link>
          </td>
          <td class="px-4 py-3 text-center whitespace-nowrap">
            <template v-if="member.job_level">
              <template v-if="member.job_level === 'senior_management'">
                <span class="badge badge-danger">{{ $t(`activerecord.attributes.member.job_level_list.${member.job_level}`) }}</span>
              </template>
              <template v-else-if="member.job_level === 'senior'">
                <span class="badge badge-primary">{{ $t(`activerecord.attributes.member.job_level_list.${member.job_level}`) }}</span>
              </template>
              <template v-else>
                <span class="badge">{{ $t(`activerecord.attributes.member.job_level_list.${member.job_level}`) }}</span>
              </template>
            </template>
          </td>
          <td class="px-4 py-3 text-center whitespace-nowrap">
            <span class="badge">{{ member.job_title }}</span>
          </td>
          <td class="px-4 py-3 text-center whitespace-nowrap">
            <CheckIcon :target="member.subscribed"></CheckIcon>
          </td>
          <td class="px-4 py-3 text-center whitespace-nowrap">{{ dateTimeFormat(member.created_at) }}</td>

          <!-- dropdown -->
          <td>
            <button id="action-dropdown-button" :data-dropdown-toggle="`action-dropdown-${member.uid}`"
                    class="inline-flex items-center p-0.5 text-sm font-medium text-center text-gray-500 hover:text-gray-800 rounded-lg focus:outline-none dark:text-gray-400 dark:hover:text-gray-100" type="button">
              <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
              </svg>
            </button>
            <Teleport to="body">
              <div :id="`action-dropdown-${member.uid}`" class="hidden z-10 w-44 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600">
                <div class="py-1 text-sm text-center text-gray-700 dark:text-gray-200">
                  <ConfirmModal class="dropdown-menu-item"
                                @submit="onDeleted(member)"
                                :buttonText="$t('helpers.delete')"
                                :modalTitle="$t('helpers.are_you_sure_delete')"
                                modalSubmitClass="btn-danger"
                                :modalSubmitText="$t('helpers.delete')">

                    <Detail :member="member"></Detail>
                  </ConfirmModal>
                </div>
              </div>
            </Teleport>
          </td>
        </template>
      </DataTable>
    </section>
  </div>
</template>

<script setup>
import { router } from '@inertiajs/vue3'
import Detail from './_detail.vue'

defineProps({
  data: { type: Array, required: true },
  paginate: { type: Object, required: true },
  select_options: { type: Object, required: true }
})

const onDeleted = (member) => {
  const url = `/members//${member.uid}`
  router.delete(url)
}
</script>
