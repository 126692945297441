<template>
  <form @submit.prevent="onSubmit" action="#" class="space-y-5">
    <div class="card">
      <div v-t="'settings_ai_setup.edit.openai'" class="card-header"></div>

      <div class="card-body">
        <!-- model -->
        <div>
          <label for="model" class="label">
            {{ $t('settings_ai_setup.edit.model') }}
          </label>
          <input v-model="form.portal_setup.settings.model" type="text" name="model" id="model" class="input">

          <p class="mt-1 text-xs text-gray-500">
            <a href="https://platform.openai.com/docs/models/models-overview" target="_blank" class="underline">{{ $t('settings_ai_setup.edit.open_ai_model_desc') }}</a>
          </p>
        </div>

        <!-- api_key -->
        <div>
          <label for="api_key" class="label">
            {{ $t('settings_ai_setup.edit.api_key') }}
          </label>
          <div class="input-group">
            <input v-if="aiOpenai.api_key && !enabledUpdatingApiKey" :value="hideValue(aiOpenai.api_key)" disabled type="text" name="api_key" id="api_key" class="input">
            <input v-else v-model="form.portal_setup.settings.api_key" type="text" name="api_key" id="api_key" class="input">
            <button v-if="aiOpenai.api_key && !enabledUpdatingApiKey" @click.prevent="onUpdateApiKey" v-t="'helpers.edit'" type="button" class="btn btn-primary btn-sm"></button>
          </div>

          <p class="mt-1 text-xs text-gray-500">
            <a href="https://platform.openai.com/api-keys" target="_blank" class="underline">{{ $t('settings_ai_setup.edit.open_ai_api_key_desc') }}</a>
          </p>
        </div>
      </div>

      <div class="card-footer space-x-3">
        <button v-t="'helpers.update'" :disabled="!form.isDirty || form.processing" type="submit" class="btn btn-primary btn-sm"></button>
      </div>
    </div>
  </form>
</template>

<script setup>
import { ref } from 'vue'
import { useForm } from '@inertiajs/vue3'

const props = defineProps({
  aiOpenai: { type: Object, required: true },
})

const enabledUpdatingApiKey = ref(false)
const defaultForm = {
  portal_setup: {
    settings: {
      model: props.aiOpenai.model,
      api_key: null
    }
  }
}
const form = useForm(defaultForm)

const hideValue = (str) => {
  return `${str.slice(0, 4)}●●●●●●●${str.slice(-4)}`
}

const onUpdateApiKey = () => {
  form.portal_setup.settings.api_key = null
  enabledUpdatingApiKey.value = true
}

const onSubmit = () => {
  form.put('/settings/ai_openai', {
    onSuccess: () => {
      enabledUpdatingApiKey.value = false
    }
  })
}
</script>
