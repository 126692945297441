<template>
  <form @submit.prevent="onSubmit" action="#" class="space-y-5">
    <div class="card">
      <div class="card-body">
        <!-- name -->
        <div>
          <label for="name" class="label">
            {{ $t('activerecord.attributes.industry.name') }}
            <abbr title="required">*</abbr>
          </label>
          <input v-model="form.industry.name" type="text" name="name" id="name" class="input" required="">

          <div v-if="form?.errors?.['name']" class="label-error">
            {{ form?.errors?.['name'][0] }}
          </div>
        </div>

        <!-- email_content_info -->
        <div>
          <label for="email_content_info" class="label">
            {{ $t('activerecord.attributes.industry.email_content_info') }}
          </label>
          <textarea v-model="form.industry.email_content_info" name="email_content_info" id="email_content_info" class="input"></textarea>

          <div v-if="form?.errors?.['email_content_info']" class="label-error">
            {{ form?.errors?.['email_content_info'][0] }}
          </div>
        </div>
      </div>
    </div>

    <div class="space-x-2">
      <template v-if="newRecord">
        <button v-t="'helpers.create'" :disabled="form.processing" type="submit" class="btn btn-primary"></button>
      </template>
      <template v-else>
        <button v-t="'helpers.update'" :disabled="form.processing" type="submit" class="btn btn-primary"></button>
      </template>

      <Link v-t="'helpers.cancel'" href="/settings/industries" class="btn"></Link>
    </div>
  </form>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3'

const props = defineProps({
  industry: { type: Object, required: true }
})

const defaultForm = {
  industry: {
    name: props.industry.name,
    email_content_info: props.industry.email_content_info
  }
}
const form = useForm(defaultForm)

const newRecord = props.industry.new_record

const onSubmit = () => {
  if (newRecord) {
    form.post('/settings/industries')
  } else {
    form.put(`/settings/industries/${props.industry.uid}`)
  }
}
</script>
