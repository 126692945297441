<template>
  <section class="space-y-5">
    <!-- card -->
    <Card :event="event"></Card>

    <!-- industry bar -->
    <div class="border border-1 rounded-lg px-5 py-3 dark:border-gray-700">
      <Bar :data="industryBarChartData()" :options="industryBarChartOptions()"></Bar>
    </div>

    <div class="grid md:grid-cols-2 gap-3">
      <!-- industry doughnut -->
      <div class="px-5 py-3 rounded-lg border dark:border-gray-700">
        <Doughnut :data="industryDoughnutChartData()" :options="industryDoughnutChartOptions()"></Doughnut>
      </div>

      <!-- job level bar -->
      <div class="px-5 py-3 rounded-lg border dark:border-gray-700">
        <Bar :data="jobLevelBarChartData()" :options="jobLevelBarChartOptions()"></Bar>
      </div>
    </div>
  </section>
</template>

<script setup>
import I18n from '@commons/i18n'
import Card from './_card.vue'
import Bar from '@components/commons/chartjs/bar.vue'
import Doughnut from '@components/commons/chartjs/doughnut.vue'

const props = defineProps({
  event: { type: Object, required: true }
})

const backgroundColors = [
  'rgba(255, 99, 132, 0.75)',
  'rgba(93, 173, 226, 0.75)',
  'rgba(255, 206, 86, 0.75)',
  'rgba(75, 192, 192, 0.75)',
  'rgba(153, 102, 255, 0.75)',
  'rgba(255, 159, 64, 0.75)',
  'rgba(123, 206, 64, 0.75)',
  'rgba(432, 206, 192, 0.75)'
]

// Industry Bar
const industryBarChartData = () => {
  const labels = Object.keys(props.event.industry_count)
  return {
    labels: labels,
    datasets: industryBarChartDatasets()
  }
}

const industryBarChartDatasets = () => {
  return [
    {
      axis: 'y',
      label: '',
      data: Object.values(props.event.industry_count),
      backgroundColor: backgroundColors,
      borderColor: backgroundColors
    }
  ]
}

const industryBarChartOptions = () => {
  const total = Object.values(props.event.industry_count).reduce((acc, cur) => acc + cur, 0)
  return {
    indexAxis: 'y',
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: true,
        text: I18n.t('events.analytics.dashboard.industry_bar')
      },
      tooltip: {
        interaction: {
          mode: 'nearest',
          axis: 'y',
          intersect: false
        },
        callbacks: {
          title: (_context) => {
            return I18n.t('events.analytics.dashboard.total_event_company', { count: total.toLocaleString() })
          },
          label: (context) => {
            const currentValue = context.raw
            const percentage = Number.parseFloat(((currentValue / total) * 100).toFixed(1))
            return `${context.label}: ${currentValue} (${percentage}%)`
          }
        }
      }
    }
  }
}

// Industry Doughnut
const industryDoughnutChartData = () => {
  const labels = Object.keys(props.event.industry_count).filter((_key, index) => index < 5).concat(I18n.t('events.analytics.dashboard.industry_list_others'))
  return {
    labels: labels,
    datasets: industryDoughnutChartDatasets()
  }
}

const industryDoughnutChartDatasets = () => {
  const firstFive = Object.values(props.event.industry_count).slice(0, 5)
  const others = Object.values(props.event.industry_count).slice(5).reduce((acc, cur) => acc + cur, 0)
  return [
    {
      label: '',
      data: [...firstFive, others],
      backgroundColor: backgroundColors,
      hoverOffset: 4
    }
  ]
}

const industryDoughnutChartOptions = () => {
  const total = Object.values(props.event.industry_count).reduce((acc, cur) => acc + cur, 0)
  return {
    responsive: true,
    plugins: {
      legend: {
        position: 'top'
      },
      title: {
        display: true,
        text: I18n.t('events.analytics.dashboard.industry_doughnut')
      },
      tooltip: {
        callbacks: {
          title: (_context) => {
            return I18n.t('events.analytics.dashboard.total_event_company', { count: total.toLocaleString() })
          },
          label: (context) => {
            const currentValue = context.raw
            const percentage = Number.parseFloat(((currentValue / total) * 100).toFixed(1))
            return `${context.label}: ${currentValue} (${percentage}%)`
          }
        }
      }
    }
  }
}

// Job Level Bar
const jobLevelBarChartData = () => {
  const labels = Object.keys(props.event.job_level_count).map((label) => {
    return I18n.t(`activerecord.attributes.member.job_level_list.${label}`)
  })
  return {
    labels: labels,
    datasets: jobLevelBarChartDatasets()
  }
}

const jobLevelBarChartDatasets = () => {
  return [
    {
      axis: 'y',
      label: '',
      data: Object.values(props.event.job_level_count),
      backgroundColor: backgroundColors,
      borderColor: backgroundColors
    }
  ]
}

const jobLevelBarChartOptions = () => {
  const total = Object.values(props.event.job_level_count).reduce((acc, cur) => acc + cur, 0)
  return {
    indexAxis: 'x',
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: true,
        text: I18n.t('events.analytics.dashboard.job_level_bar')
      },
      tooltip: {
        interaction: {
          mode: 'nearest',
          axis: 'y',
          intersect: false
        },
        callbacks: {
          title: (_context) => {
            return I18n.t('events.analytics.dashboard.total_event_member', { count: total.toLocaleString() })
          },
          label: (context) => {
            const currentValue = context.raw
            const percentage = Number.parseFloat(((currentValue / total) * 100).toFixed(1))
            return `${context.label}: ${currentValue} (${percentage}%)`
          }
        }
      }
    }
  }
}
</script>
