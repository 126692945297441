import { defineStore } from 'pinia'

export const useThemeStore = defineStore('stores/theme/store', {
  state: () => {
    let themeMode = 'light'
    if (
      localStorage.getItem('color-theme') === 'dark' ||
      (!('color-theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)
    ) {
      themeMode = 'dark'
    }

    return {
      themeMode
    }
  },

  actions: {},

  getters: {
    mode() {
      return this.themeMode
    }
  }
})
