<template>
  <div class="mx-auto max-w-screen-xl py-4 px-4 space-y-5">
    <PageHeader :title="$t('events.nav.event_emails')">
      <template #breadcrumb>
        <PageHeaderItem :title="$t('nav.event')" href="/events"></PageHeaderItem>
        <PageHeaderItem :title="event.name" :href="`/events/${event.uid}/analytics`"></PageHeaderItem>
        <PageHeaderItem :title="$t('events.nav.event_emails')"></PageHeaderItem>
      </template>

      <template #functional>
        <template v-if="eventEmailsChecked.length > 0">
          <a @click.prevent="onConfirmAll" href="javascript:;" class="btn btn-primary">
            {{ $t('helpers.confirm_all') }}
            ({{ eventEmailsChecked.length }})
          </a>
        </template>

        <!-- replace email content -->
        <ReplaceEmailContent v-if="pundit_policy.event_email.replace_emails" :event="event" :eventEmailsChecked="eventEmailsChecked" :defaultEmail="default_email"></ReplaceEmailContent>

        <Link v-if="pundit_policy.event_email.reassign_emails" :href="`/events/${props.event.uid}/emails/reassign_emails`" method="post" as="button" class="btn btn-primary">
          {{ $t('event_emails.index.reassign') }}
        </Link>
      </template>
    </PageHeader>

    <section class="space-y-5">
      <!-- nav -->
      <Nav :event="event"></Nav>

      <!-- sales -->
      <div class="flex justify-between gap-3">
        <div v-for="(saleInfo, saleName) in emailCount" :key="saleName" class="px-4 py-2 bg-gray-500 rounded-lg w-full">
          <h2 class="font-medium text-xs text-white">{{ saleName }}</h2>
          <span class="text-white text-2xl">
            {{ numberFormatWithoutDecimal(saleInfo.sent_count) }} / {{ numberFormatWithoutDecimal(saleInfo.total_count) }}
          </span>
        </div>
      </div>

      <div class="grid grid-cols-none md:grid-cols-12 gap-4">
        <div class="col-span-full md:col-span-2">
          <EmailNav :event="event" :skipEventVerifierEmails="!pundit_policy.verifier_event_email"></EmailNav>
        </div>
        <div class="col-span-full md:col-span-10">
          <DataTable :resources="data" :paginate="paginate" :searchPlaceholder="$t('event_emails.index.search_placeholder')">
            <template #thead>
              <DataTableHead v-if="pundit_policy.event_email.confirm_all" class="text-center sticky left-0 bg-gray-50 dark:bg-gray-700">
                <template v-if="checkable.length > 0">
                  <input @change="onChangeAll" v-model="checkedAll" type="checkbox" value="" class="checkbox">
                </template>
              </DataTableHead>
              <DataTableHead :text="$t('activerecord.attributes.member.full_name')" class="text-center sticky left-12 bg-gray-50 dark:bg-gray-700" />
              <DataTableHead :text="$t('activerecord.attributes.member.email')" class="text-center" />
              <DataTableHead :text="$t('activerecord.attributes.event_email.status')" class="text-center whitespace-nowrap"
                             :selectOptions="select_options.status_options" id="status" />
              <DataTableHead :text="$t('activerecord.attributes.member.company_name')" class="text-center whitespace-nowrap" />
              <DataTableHead :text="$t('activerecord.attributes.company.industry')" class="text-center"
                             :selectOptions="select_options.industry_options" id="industry" />
              <DataTableHead :text="$t('activerecord.attributes.event_email_content.content')" class="text-center whitespace-nowrap" />
              <DataTableHead :text="$t('activerecord.attributes.event_email.sale')" class="text-center whitespace-nowrap"
                             :selectOptions="select_options.sales_options" id="sales" />
              <DataTableHead :text="$t('activerecord.attributes.event_email.verifier')" class="text-center whitespace-nowrap" />
              <DataTableHead id="created_at" :text="$t('activerecord.attributes.event_email.sent_at')" class="text-center whitespace-nowrap" />
              <DataTableHead id="created_at" :text="$t('activerecord.attributes.company.created_at')" class="text-center whitespace-nowrap" defaultOrderColumn />
            </template>

            <template #tbody-tr="{resource: eventEmail}">
              <td v-if="pundit_policy.event_email.confirm_all" class="px-4 py-3 text-center sticky left-0 min-w-[48px] bg-white dark:bg-gray-800">
                <template v-if="['draft', 'failed'].includes(eventEmail.status) && (props.currentUser.role === 'admin' || eventEmail.user.name === currentUser.name)">
                  <input v-model="eventEmail.checked" type="checkbox" value="" class="checkbox">
                </template>
              </td>
              <td class="px-4 py-3 text-center font-semibold whitespace-nowrap text-blue-600 dark:text-blue-400 sticky left-12 bg-white dark:bg-gray-800">
                {{ eventEmail.member.full_name }}
              </td>
              <td class="px-4 py-3 text-center whitespace-nowrap underline">
                {{ eventEmail.member.email }}
              </td>
              <td class="px-4 py-3 text-center whitespace-nowrap">
                <template v-if="eventEmail.status === 'sent'">
                  <span class="badge badge-primary">{{ $t(`activerecord.attributes.event_email.status_list.${eventEmail.status}`) }}</span>
                </template>
                <template v-else-if="eventEmail.status === 'failed'">
                  <span class="badge badge-danger">{{ $t(`activerecord.attributes.event_email.status_list.${eventEmail.status}`) }}</span>
                </template>
                <template v-else-if="eventEmail.status === 'queued'">
                  <span class="badge badge-info">{{ $t(`activerecord.attributes.event_email.status_list.${eventEmail.status}`) }}</span>
                </template>
                <template v-else>
                  <span class="badge">{{ $t(`activerecord.attributes.event_email.status_list.${eventEmail.status}`) }}</span>
                </template>
              </td>
              <td class="px-4 py-3 text-center whitespace-nowrap">
                {{ eventEmail.member.company_name }}
              </td>
              <td class="px-4 py-3 text-center whitespace-nowrap">
                <span class="badge badge-primary">{{ eventEmail.industry.name }}</span>
              </td>
              <td class="px-4 py-3 text-center whitespace-nowrap">
                <EmailContent :event="event" :eventEmail="eventEmail" :currentUser="currentUser" :pundit_policy="pundit_policy"></EmailContent>
              </td>
              <td class="px-4 py-3 text-center whitespace-nowrap">
                <template v-if="pundit_policy.event_email.switch_sales_rep && !eventEmail.verifier">
                  <SwitchSalesRep :event="event" :eventEmail="eventEmail" :salesOptions="select_options.sales_options_with_id"></SwitchSalesRep>
                </template>
                <template v-else-if="eventEmail.user.name">
                  <div>
                    <span class="badge badge-primary">{{ eventEmail.user.name }}</span>
                  </div>
                </template>
                <template v-else>
                  <span class="badge badge-danger">{{ $t('activerecord.attributes.event_email.no_assign') }}</span>
                </template>
              </td>
              <td class="px-4 py-3 text-center whitespace-nowrap">
                <span v-if="eventEmail.verifier" class="badge badge-primary">{{ eventEmail.verifier.name }}</span>
              </td>
              <td class="px-4 py-3 text-center whitespace-nowrap">{{ dateTimeFormat(eventEmail.sent_at) }}</td>
              <td class="px-4 py-3 text-center whitespace-nowrap">{{ dateTimeFormat(eventEmail.created_at) }}</td>
            </template>
          </DataTable>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import Nav from './../_nav.vue'
import EmailNav from './../_email_nav.vue'
import EmailContent from './_email_content.vue'
import ReplaceEmailContent from './_replace_email_content.vue'
import SwitchSalesRep from './_switch_sales_rep.vue'
import { watch, ref, computed } from 'vue'
import { router } from '@inertiajs/vue3'
import axios from 'axios'

const props = defineProps({
  event: { type: Object, required: true },
  data: { type: Array, required: true },
  paginate: { type: Object, required: true },
  default_email: { type: Object, required: true },
  select_options: { type: Object, required: true },
  currentUser: { type: Object, required: true },
  pundit_policy: { type: Object, required: true }
})

const checkedAll = ref(false)
const checkableStatus = ['draft', 'failed']
const eventEmailsChecked = computed(() => {
  return props.data.filter((eventEmail) => eventEmail.checked)
})

const checkable = computed(() => {
  return props.data.filter((eventEmail) => checkableStatus.includes(eventEmail.status) && (props.currentUser.role === 'admin' || eventEmail.user.name === props.currentUser.name))
})

const emailCount = computed(() => {
  const sortedEntries = Object.entries(props.event.email_count).sort((a, b) => b[1].total_count - a[1].total_count);
  return Object.fromEntries(sortedEntries);
})

const onChangeAll = (e) => {
  for (const eventEmail of props.data) {
    if (checkableStatus.includes(eventEmail.status) && (props.currentUser.role === 'admin' || eventEmail.user.name === props.currentUser.name)) {
      eventEmail.checked = e.target.checked
    }
  }
}

const onConfirmAll = () => {
  const url = `/events/${props.event.uid}/emails/confirm_all`
  const uids = eventEmailsChecked.value.map((eventEmail) => eventEmail.uid)
  const params = { uid: uids }

  axios.post(url, params)
    .then(() => {
      router.reload()
    })
}

watch(eventEmailsChecked, (value) => {
  if (value.length === checkable.value.length) {
    checkedAll.value = true
  } else {
    checkedAll.value = false
  }
})
</script>
