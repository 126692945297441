<template>
  <!-- Modal toggle -->
  <a @click="toggleModal" class="btn btn-primary py-2 px-2.5 text-xs">
    {{ $t('helpers.view') }}
  </a>

  <!-- Main modal -->
  <Teleport to="body">
    <!-- Main modal -->
    <div ref="modalEl" tabindex="-1" aria-hidden="true" class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
      <div class="relative p-4 w-full max-w-3xl max-h-full">
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <!-- Modal header -->
          <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
            <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
              {{ $t('activerecord.attributes.event_email_content.content') }}
            </h3>
            <button @click="closeModal" type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
              <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
              </svg>
              <span class="sr-only">Close modal</span>
            </button>
          </div>
          <!-- Modal body -->
          <form @submit.prevent="onUpdate" class="p-4 md:p-5">
            <div class="grid gap-4 mb-4 grid-cols-2">
              <div class="col-span-2">
                <label v-t="'activerecord.attributes.event_email_content.subject'" for="subject" class="label"></label>
                <input v-model="form.email_content.subject" :readonly="isSent" type="text" name="subject" id="subject" class="input" required="">

                <div v-if="errors?.['subject']" class="label-error">
                  {{ errors?.['subject'][0] }}
                </div>
              </div>
              <div class="col-span-2">
                <div class="flex justify-between items-center space-x-2 mb-1">
                  <label v-t="'activerecord.attributes.event_email_content.content'" for="content" class="label mb-0"></label>

                  <div v-if="!isSent" class="space-x-2">
                    <button v-if="emailContentLoading" class="btn btn-xs" type="button">
                      <i class="fa-solid fa-rotate fa-spin"></i>
                    </button>

                    <button v-if="emailContentChange" @click="onResetContent" class="btn btn-xs" type="button">
                      <i class="fa-solid fa-rotate-left"></i>
                    </button>

                    <button v-if="pundit_policy.event_email_content.update" ref="dropdownButtonEl" class="btn btn-outline-primary btn-xs" type="button">
                      {{ $t('event_emails.email_content.ai.title') }}
                      <i class="fa-solid fa-sparkles"></i>
                    </button>
                  </div>

                  <!-- Dropdown menu -->
                  <div ref="dropdownMenuEl" id="dropdown" class="z-50 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                    <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
                      <li>
                        <button @click="onGenerative('professional')" v-t="'event_emails.email_content.ai.list.pro'" class="w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white" type="button"></button>
                      </li>
                      <li>
                        <button @click="onGenerative('casual')" class="w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white" type="button">
                          {{ $t('event_emails.email_content.ai.list.casual') }}
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
                <textarea v-model="form.email_content.content" :readonly="isSent" :disabled="emailContentLoading" rows="17" name="content" id="content" class="input" required="" />

                <div v-if="errors?.['content']" class="label-error">
                  {{ errors?.['content'][0] }}
                </div>
              </div>
            </div>
          </form>
          <!-- Modal footer -->
          <div v-if="!isSent && pundit_policy.event_email_content.update" class="flex items-center justify-end gap-2 p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
            <button @click="onConfirm" type="button" class="btn btn-primary" :disabled="eventEmail.status != 'draft' || notCurrentUser()">
              {{ $t('helpers.confirm') }} & {{ $t('helpers.update') }}
            </button>
            <button @click="onUpdate" v-t="'helpers.update'" type="button" class="btn btn-primary" :disabled="notCurrentUser()"></button>
            <button v-t="'helpers.cancel'" @click="closeModal" type="button" class="btn"></button>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup>
import { onMounted, computed, ref, reactive, onUpdated } from 'vue'
import { router } from '@inertiajs/vue3'
import { Modal, Dropdown } from 'flowbite'
import axios from 'axios'

const props = defineProps({
  event: { type: Object, required: true },
  eventEmail: { type: Object, required: true },
  currentUser: { type: Object, required: true },
  pundit_policy: { type: Object, required: true }
})

const modalEl = ref(undefined)
const dropdownButtonEl = ref(undefined)
const dropdownMenuEl = ref(undefined)
const modal = ref(undefined)
const dropdown = ref(undefined)
const errors = ref(undefined)
const tempEmailContent = ref(props.eventEmail.email_content.content)
const emailContentChange = ref(false)
const emailContentLoading = ref(false)
const form = reactive({
  email_content: {
    uid: null, subject: null, content: null,
  }
})

const isSent = computed(() => ['queued', 'sent'].includes(props.eventEmail.status))
const notCurrentUser = () => !(props.currentUser.role === 'admin' || props.eventEmail.user.name === props.currentUser.name)

const setupForm = () => {
  form.email_content.uid = props.eventEmail.email_content.uid
  form.email_content.subject = props.eventEmail.email_content.subject
  form.email_content.content = props.eventEmail.email_content.content
}

const onConfirm = () => {
  const url = `/events/${props.event.uid}/emails/${props.eventEmail.uid}/email_contents/confirm`
  const params = {
    email_content: {
      subject: form.email_content.subject,
      content: form.email_content.content,
    }
  }

  axios.put(url, params)
    .then(response => {
      router.reload()
      modal.value.toggle()
    })
    .catch(error => {
      errors.value = error.response.data.errors
    })
}

const onUpdate = () => {
  const url = `/events/${props.event.uid}/emails/${props.eventEmail.uid}/email_contents/${props.eventEmail.email_content.uid}`
  const params = {
    email_content: {
      subject: form.email_content.subject,
      content: form.email_content.content,
    }
  }

  axios.put(url, params)
    .then(response => {
      modal.value.toggle()
    })
    .catch(error => {
      console.log(error)
      errors.value = error.response.data.errors
    })
}

const onGenerative = (type) => {
  dropdown.value.hide()
  if (!['professional', 'casual'].includes(type)) {
    return
  }

  emailContentLoading.value = true
  const url = `/events/${props.event.uid}/emails/${props.eventEmail.uid}/email_contents/${props.eventEmail.email_content.uid}/${type}`
  axios.post(url)
    .then(response => {
      emailContentChange.value = true
      form.email_content.content = response.data.content
    })
    .catch(error => {
      console.log(error)
    })
    .finally(() => {
      emailContentLoading.value = false
    })
}

const onResetContent = () => {
  form.email_content.content = tempEmailContent.value
  emailContentChange.value = false
}

const toggleModal = () => {
  modal.value.toggle()
}

const closeModal = () => {
  modal.value.hide()
}

onMounted(() => {
  modal.value = new Modal(modalEl.value, {
    onShow: () => {
      if (!props.eventEmail.sent_at) { dropdown.value = new Dropdown(dropdownMenuEl.value, dropdownButtonEl.value) }
      localStorage.setItem('enableKeyboard', false)
    },
    onHide: () => {
      localStorage.setItem('enableKeyboard', true)
    }
  })
})

onUpdated(() => {
  setupForm()
})

setupForm()
</script>
