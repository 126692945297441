<template>
  <form @submit.prevent="onSubmit" action="#" class="space-y-5">
    <div class="card">
      <div class="card-body">
        <!-- name -->
        <div>
          <label for="name" class="label">
            {{ $t('activerecord.attributes.user.name') }}
            <abbr title="required">*</abbr>
          </label>
          <input v-model="form.user.name" type="text" name="name" id="name" class="input" required="">

          <div v-if="form?.errors?.['name']" class="label-error">
            {{ form?.errors?.['name'][0] }}
          </div>
        </div>

        <!-- role -->
        <div>
          <label for="role" class="label">
            {{ $t('activerecord.attributes.user.role') }}
            <abbr title="required">*</abbr>
          </label>
          <select v-model="form.user.role" id="role" class="select" required="">
            <option v-t="'helpers.plz_select'" :value="null" disabled></option>
            <option v-for="(role, index) in options.role_options" :key="index" :value="role">{{ $t(`activerecord.attributes.user.role_list.${role}`) }}</option>
          </select>

          <div v-if="form?.errors?.['role']" class="label-error">
            {{ form?.errors?.['role'][0] }}
          </div>
        </div>

        <!-- enable -->
        <div>
          <label for="enable" class="label">
            {{ $t('activerecord.attributes.user.enable') }}
            <abbr title="required">*</abbr>
          </label>
          <select v-model="form.user.enable" id="enable" class="select" required="">
            <option :value="true">{{ $t('activerecord.attributes.user.enable_list.true') }}</option>
            <option :value="false">{{ $t('activerecord.attributes.user.enable_list.false') }}</option>
          </select>

          <div v-if="form?.errors?.['enable']" class="label-error">
            {{ form?.errors?.['enable'][0] }}
          </div>
        </div>

        <!-- industry -->
        <div>
          <label for="industries" class="label">
            {{ $t('activerecord.attributes.user.industry') }}
            <abbr title="required">*</abbr>
          </label>
          <Multiselect v-model="industrySelected"
                       id="industry_id"
                       :options="options.industry_options"
                       :multiple="true"
                       :close-on-select="false"
                       :clear-on-select="false"
                       :preserve-search="true"
                       :placeholder="$t('helpers.plz_select')"
                       :searchable="true"
                       label="name"
                       track-by="id">
          </Multiselect>

          <div v-if="form?.errors?.['industry_id']" class="label-error">
            {{ form?.errors?.['industry_id'][0] }}
          </div>
        </div>
      </div>
    </div>

    <div class="space-x-2">
      <template v-if="newRecord">
        <button v-t="'helpers.create'" :disabled="form.processing" type="submit" class="btn btn-primary"></button>
      </template>
      <template v-else>
        <button v-t="'helpers.update'" :disabled="form.processing" type="submit" class="btn btn-primary"></button>
      </template>

      <Link v-t="'helpers.cancel'" href="/settings/users" class="btn"></Link>
    </div>
  </form>
</template>

<script setup>
import { ref, watch } from 'vue'
import { useForm } from '@inertiajs/vue3'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'

const props = defineProps({
  user: { type: Object, required: true },
  options: { type: Object, required: true }
})

const defaultForm = {
  user: {
    name: props.user.name,
    role: props.user.role,
    enable: props.user.enable
  },
  industries: props.user.industries
}
const form = useForm(defaultForm)

const newRecord = props.user.new_record

const industrySelected = ref(props.options.industry_options.filter((industry) => {
  return props.user.industries.some((userIndustryId) => {
    return userIndustryId === industry.id
  })
}))

watch(industrySelected, (newValue) => {
  updateIndustriesAttributes(newValue)
})

const updateIndustriesAttributes = (industries) => {
  form.industries = industries.map((industry) => {
    return industry.id
  })
}

const onSubmit = () => {
  if (newRecord) {
    form.post('/settings/users')
  } else {
    form.put(`/settings/users/${props.user.uid}`)
  }
}
</script>
