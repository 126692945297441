<template>
  <li class="inline-flex items-center">
    <template v-if="href">
      <Link :href="href" class="inline-flex items-center text-sm font-medium text-gray-500 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
        <svg v-if="!skipArrow" class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
        </svg>

        <span v-if="hasDefaultSlot || title" class="ms-1 text-sm font-medium ">
          <slot>{{ title }}</slot>
        </span>
      </Link>
    </template>
    <template v-else>
      <div class="flex items-center">
        <svg v-if="!skipArrow" class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
        </svg>

        <span v-if="hasDefaultSlot || title" class="ms-1 text-sm font-medium text-gray-500 dark:text-gray-400">
          <slot>{{ title }}</slot>
        </span>
      </div>
    </template>
  </li>
</template>

<script setup>
import { computed, useSlots } from 'vue'

defineProps({
  href: { type: String },
  title: { type: String },
  skipArrow: { type: Boolean, default: false }
})

const slots = useSlots()

const hasDefaultSlot = computed(() => {
  return !!slots['default']
})
</script>
