<template>
  <section class="bg-white space-y-5 dark:bg-gray-900">
    <div class="grid grid-cols-2 gap-3 dark:border-gray-700 dark:divide-gray-700">
      <div class="px-5 py-3 bg-blue-500 rounded-lg">
        <h2 v-t="'events.analytics.index.total_event_company'" class="font-medium text-xs text-white mb-2"></h2>
        <span class="text-white text-4xl">
          {{ numberFormatWithoutDecimal(event.company_count) }}
        </span>
      </div>
      <div class="px-5 py-3 bg-blue-500 rounded-lg">
        <h2 v-t="'events.analytics.index.total_event_member'" class="font-medium text-xs text-white mb-2"></h2>
        <span class="text-white text-4xl">
          {{ numberFormatWithoutDecimal(event.member_count) }}
        </span>
      </div>
    </div>

    <!-- sales -->
    <div class="flex justify-between gap-3">
      <div v-for="(saleInfo, saleName) in emailCount" :key="saleName" class="px-5 py-3 bg-blue-500 rounded-lg w-full">
        <h2 class="font-medium text-xs text-white mb-2">{{ saleName }}</h2>
        <span class="text-white text-4xl">
          {{ numberFormatWithoutDecimal(saleInfo.total_count) }}
        </span>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  event: { type: Object, required: true }
})

const emailCount = computed(() => {
  const sortedEntries = Object.entries(props.event.email_count).sort((a, b) => b[1].total_count - a[1].total_count);
  return Object.fromEntries(sortedEntries);
})
</script>
