<template>
  <div class="tabs">
    <ul>
      <template v-if="event.status === 'complete'">
        <!-- event analytics -->
        <li :class="navIsActive(`/events/${event.uid}/analytics`, 'startsWith')">
          <Link :href="`/events/${event.uid}/analytics`">
            {{ $t('events.nav.analytics') }}
          </Link>
        </li>

        <!-- event companies -->
        <li :class="navIsActive(`/events/${event.uid}/companies`, 'startsWith')">
          <Link :href="`/events/${event.uid}/companies`">
            {{ $t('events.nav.event_companies') }}
            ({{ event.company_count }})
          </Link>
        </li>

        <!-- event user -->
        <li :class="navIsActive(`/events/${event.uid}/members`, 'startsWith')">
          <Link :href="`/events/${event.uid}/members`">
            {{ $t('events.nav.event_members') }}
            ({{ event.member_count }})
          </Link>
        </li>

        <!-- event emails -->
        <li :class="navIsActive([`/events/${event.uid}/emails`, `/events/${event.uid}/verifier_emails`], 'startsWith')">
          <Link :href="`/events/${event.uid}/emails`">
            {{ $t('events.nav.event_emails') }}
            ({{ event.not_send_count }})
          </Link>
        </li>
      </template>

      <!-- event profile -->
      <li :class="navIsActive(`/events/${event.uid}`)">
        <Link v-t="'events.nav.profile'" :href="`/events/${event.uid}`"></Link>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { onUpdated } from 'vue'
import { initDropdowns } from 'flowbite'

defineProps({
  event: { type: Object, required: true }
})

// initialize components based on data attribute selectors
onUpdated(() => {
  initDropdowns()
})
</script>
